<template>
  <div class="result-page">
    <div class="not-vip" v-if="userinfo.sendGift">
      <lottie-player
        class="gift-animation"
        src="https://res.yimiaopan.com/pan/lottie/gift.json"
        background="transparent"
        speed="0.8"
        autoplay
      />
      <p class="tip warning em">
        检测到你是早期网站的支持者，系统已赠送您 1 个月会员
      </p>
      <p class="warn-tip">
        声明：<span class="em">网站已升级为内部会员专享</span
        >，搜索引擎结果具备动态不确定性，如介意结果不满意，请勿捐赠，有问题请联系：shakanamo945@gmail.com
      </p>
      <van-button class="vip-button" type="primary" @click="handleGetGift"
        >领取奖励</van-button
      >
    </div>
    <div class="not-vip" v-else>
      <lottie-player
        class="gift-animation"
        src="https://res.yimiaopan.com/pan/lottie/warning.json"
        background="transparent"
        speed="0.8"
        autoplay
      />
      <template v-if="userinfo.expiredate">
        <p class="tip warning em">
          您的会员资格有效时间至： {{ userinfo.expiredate | formatDate }}
        </p>
        <van-button class="vip-button" type="primary" @click="goPayPage"
          >续费会员资格</van-button
        >
      </template>
      <template v-else>
        <p class="tip warning em">检测到您还不是本站会员</p>
        <van-button class="vip-button" type="primary" @click="goPayPage"
          >立刻捐赠并成为会员</van-button
        >
      </template>
      <p class="info-tip em">
        本站已收录：3000万+ 百度网盘资源，1亿+ 阿里网盘资源
      </p>
      <div class="warn-tip em">
        站点会员捐赠说明：<br />
        1.支持无理由退捐，请联系QQ：3556044645，承诺7x24小时内响应处理<br />
        2.搜索引擎结果具备动态不确定性，如介意结果不满意，请勿捐赠<br />
        3.有问题可邮箱留言：shakanamo945@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.userinfo && !this.userinfo.expired) {
      this.$router.replace({ name: "search" });
    }
  },
  methods: {
    goPayPage() {
      this.$router.replace({ name: "pay" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.result-page {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 30px;
  min-height: 400px;
}

.not-vip {
  padding-top: 30px;
  text-align: center;

  .gift-animation {
    width: 200px;
  }

  .tip {
    margin-top: 10px;

    &.big {
      font-size: 15px;
    }
  }

  .info-tip {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
  }

  .warn-tip {
    margin: 10px auto;
    width: 480px;
    max-width: 100%;
    padding: 10px;
    color: rgb(128, 82, 50);
    font-size: 12px;
    line-height: 1.6;
    border-radius: 2px;
    background-color: #fffbe8;
    text-align: left;
    border: 1px solid rgba(73, 33, 5, 0.2);
  }

  .vip-button {
    width: 200px;
    margin-top: 20px;
    border-radius: 8px;
  }
}

@media (max-width: 700px) {
  .result-page {
    padding: 15px;
  }
}
</style>
